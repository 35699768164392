const moneyFormat = (balance: number) => {
  return new Intl.NumberFormat('es-HN', { style: 'currency', currency: 'HNL' }).format(balance);
};

export const isValidCurrencyFormat = (value: string) => {
  const [units, decimals = '00'] = value.split('.');
  const moneyValue = Number(units) + Number(decimals.padEnd(2, '0')) / 100;
  if (Number.isNaN(moneyValue) || decimals.length > 2) {
    return false;
  }
  return true;
};

export default moneyFormat;
