



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AwesomeQR } from 'awesome-qr';

@Component({
  name: 'QrCode',
})
export default class QrCode extends Vue {
  @Prop({ required: true }) value!: string;

  @Prop({ default: '' }) text!: string;

  @Prop({ default: '/lempi-logo.svg' }) image!: string;

  src?: string | Buffer | ArrayBuffer = '';

  snackbar = false;

  @Watch('value')
  regenerateQrCode() {
    this.generateQrCode();
  }

  created() {
    this.generateQrCode();
  }

  generateQrCode() {
    new AwesomeQR({
      text: this.value,
      size: 800,
      backgroundImage: this.image,
      components: {
        data: {
          scale: 0.75,
        },
        timing: {
          scale: 0.7,
        },
        alignment: {
          scale: 1,
        },
        cornerAlignment: {
          scale: 0.7,
        },
      },
    })
      .draw()
      .then((dataURL) => {
        this.src = dataURL;
      });
  }

  copyValue() {
    this.snackbar = true;
    navigator.clipboard.writeText(this.value);
  }

  get renderedText() {
    return this.text || this.value;
  }
}
