const dateTimeFormat = (rawDate: string | Date) => {
  let date: Date;
  if (typeof rawDate === 'string') {
    date = new Date(rawDate);
  } else {
    date = rawDate;
  }
  return new Intl.DateTimeFormat('es-HN-u-hc-h12', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(date);
};

export default dateTimeFormat;
